import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useFirestore } from "reactfire";
import { setShifts } from "../features/signUp";
import { useAppDispatch } from "../store/hooks";
import { CourseCampus } from "../types/courseCampus";
import { EventDate } from "../types/eventDate";

type Item = {
  id: string;
  name: string;
};

export default () => {
  const firestore = useFirestore();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const courseCampusID = searchParams.get("curso") || "";
  const mode = searchParams.get("mode") || "college";
  const [eventDate, setEventDate] = useState<EventDate | undefined>(undefined);
  const [eventDates, setEventDates] = useState<EventDate[] | undefined>(
    undefined
  );
  const [courses, setCourses] = useState<CourseCampus[]>([]);
  const [seriesCollection, setSeriesCollection] = useState<Item[]>([]);
  const [shiftsCollection, setShiftsCollection] = useState<Item[]>([]);
  const [typesCollection, setTypesCollection] = useState<Item[]>([]);
  const [campusesCollection, setCampusesCollection] = useState<Item[]>([]);
  const [courseCampus, setCourseCampus] = useState<CourseCampus | undefined>(
    undefined
  );

  useEffect(() => {
    loadCourseCampus();
    loadCampuses();
    loadTypes();
    loadShifts();
    loadSeries();
    loadCourses();
    loadDates();
  }, []);

  const loadCourseCampus = useCallback(async () => {
    const courseCampusCollectionRef = collection(firestore, "coursesCampus");
    const courseCampusQuery = query(
      courseCampusCollectionRef,
      where("totvsID", "==", courseCampusID),
      limit(1)
    );
    const courseCampusCollection = await getDocs(courseCampusQuery);
    const courseCampus = courseCampusCollection.docs.map((item) => ({
      ...item.data(),
      id: item.id,
    })) as CourseCampus[];
    setCourseCampus(courseCampus[0]);
  }, []);

  const loadCampuses = useCallback(async () => {
    const campusesRef = collection(firestore, "campuses");
    const campusesCollection = await getDocs(campusesRef);
    const campuses = campusesCollection.docs.map((item) => ({
      ...item.data(),
      id: item.id,
    })) as Item[];
    setCampusesCollection(campuses);
  }, []);

  const loadTypes = useCallback(async () => {
    const typesRef = collection(firestore, "types");
    const typesCollection = await getDocs(typesRef);
    const types = typesCollection.docs.map((item) => ({
      ...item.data(),
      id: item.id,
    })) as Item[];
    setTypesCollection(types);
  }, []);

  const loadShifts = useCallback(async () => {
    const shiftsRef = collection(firestore, "shifts");
    const shiftsCollection = await getDocs(shiftsRef);
    const shifts = shiftsCollection.docs.map((item) => ({
      ...item.data(),
      id: item.id,
    })) as Item[];
    setShiftsCollection(shifts);
  }, []);

  const loadSeries = useCallback(async () => {
    const seriesRef = collection(firestore, "series");
    const seriesCollection = await getDocs(seriesRef);
    const series = seriesCollection.docs.map((item) => ({
      ...item.data(),
      id: item.id,
    })) as Item[];
    setSeriesCollection(series);
  }, []);

  const loadCourses = useCallback(async () => {
    const coursesRef = collection(firestore, "courses");
    const coursesQuery = query(
      coursesRef,
      where("active", "==", true),
      where("mode", "==", mode),
      orderBy("type"),
      orderBy("name")
    );
    const coursesCollection = await getDocs(coursesQuery);
    const courses = coursesCollection.docs.map((item) => ({
      ...item.data(),
      id: item.id,
    })) as CourseCampus[];
    setCourses(courses);
  }, []);

  const loadDates = useCallback(async () => {
    const datesRef = collection(firestore, "dates");

    if (mode === "school") {
      const dates0Query = query(
        datesRef,
        where("mode", "==", mode),
        where("dow", "==", 0),
        where("limitTimestamp", ">", new Date()),
        orderBy("limitTimestamp", "asc"),
        limit(1)
      );
      const dates0Collection = await getDocs(dates0Query);

      const dates3Query = query(
        datesRef,
        where("mode", "==", mode),
        where("dow", "==", 3),
        where("limitTimestamp", ">", new Date()),
        orderBy("limitTimestamp", "asc"),
        limit(1)
      );
      const dates3Collection = await getDocs(dates3Query);

      const date0 = dates0Collection.empty
        ? undefined
        : (dates0Collection.docs[0].data() as EventDate);
      const date3 = dates3Collection.empty
        ? undefined
        : (dates3Collection.docs[0].data() as EventDate);

      if (date0 && date3) {
        setEventDates(
          [
            {
              ...date0,
              id: dates0Collection.docs[0].id,
              limitTimestamp: dates0Collection.docs[0]
                .data()
                .limitTimestamp.toDate() as Date,
              timestamp: dates0Collection.docs[0]
                .data()
                .timestamp.toDate() as Date,
            },
            {
              ...date3,
              id: dates3Collection.docs[0].id,
              limitTimestamp: dates3Collection.docs[0]
                .data()
                .limitTimestamp.toDate() as Date,
              timestamp: dates3Collection.docs[0]
                .data()
                .timestamp.toDate() as Date,
            },
          ].sort(
            (a, b) => a.limitTimestamp.getTime() - b.limitTimestamp.getTime()
          )
        );
      } else if (date0) {
        setEventDates([
          {
            ...date0,
            id: dates0Collection.docs[0].id,
            limitTimestamp: dates0Collection.docs[0]
              .data()
              .limitTimestamp.toDate() as Date,
            timestamp: dates0Collection.docs[0]
              .data()
              .timestamp.toDate() as Date,
          },
        ]);
      } else if (date3) {
        setEventDates([
          {
            ...date3,
            id: dates3Collection.docs[0].id,
            limitTimestamp: dates3Collection.docs[0]
              .data()
              .limitTimestamp.toDate() as Date,
            timestamp: dates3Collection.docs[0]
              .data()
              .timestamp.toDate() as Date,
          },
        ]);
      }
    } else {
      const datesQuery = query(
        datesRef,
        where("mode", "==", mode),
        where("limitTimestamp", ">", new Date()),
        orderBy("limitTimestamp", "asc"),
        limit(1)
      );
      const datesCollection = await getDocs(datesQuery);

      if (!datesCollection.empty) {
        const date = datesCollection.docs[0].data() as EventDate;
        setEventDate({
          ...date,
          id: datesCollection.docs[0].id,
          limitTimestamp: datesCollection.docs[0]
            .data()
            .limitTimestamp.toDate() as Date,
          timestamp: datesCollection.docs[0].data().timestamp.toDate() as Date,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (shiftsCollection)
      dispatch(
        setShifts(
          shiftsCollection.map((item) => ({
            id: item.id,
            name: item.name,
          }))
        )
      );
  }, [shiftsCollection]);

  useEffect(() => {
    if (campusesCollection)
      dispatch(
        setShifts(
          campusesCollection.map((item) => ({
            id: item.id,
            name: item.name,
          }))
        )
      );
  }, [campusesCollection]);

  useEffect(() => {
    if (typesCollection)
      dispatch(
        setShifts(
          typesCollection.map((item) => ({
            id: item.id,
            name: item.name,
          }))
        )
      );
  }, [typesCollection]);

  useEffect(() => {
    if (seriesCollection)
      dispatch(
        setShifts(
          seriesCollection.map((item) => ({
            id: item.id,
            name: item.name,
          }))
        )
      );
  }, [seriesCollection]);

  const getCampus = (id: string) => {
    return campusesCollection?.find((item) => item.id === id);
  };

  const getShift = (id: string) => {
    return shiftsCollection?.find((item) => item.id === id);
  };

  const getType = (id: string) => {
    return typesCollection?.find((item) => item.id === id);
  };

  const getSeries = (id: string) => {
    return seriesCollection?.find((item) => item.id === id);
  };

  return {
    courseCampus,
    courses,
    eventDate,
    eventDates,
    getCampus,
    getShift,
    getType,
    getSeries,
  };
};
